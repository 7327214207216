import React, { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Typography, Link, Tooltip } from '@mui/material';
import BadgeSharp from '@mui/icons-material/BadgeSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ShuffleSharp from '@mui/icons-material/ShuffleSharp';
import PriorityHighSharp from '@mui/icons-material/PriorityHighSharp';
import RuleSharp from '@mui/icons-material/RuleSharp';
import CheckCircleOutlineSharp from '@mui/icons-material/CheckCircleOutlineSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import { ODAvatar, ODCheckbox, ODTooltip, ODRadio } from '@OptimalDynamics/core-ai-common-ui';
import { useNavigate } from 'react-router-dom';
import { CopyClick } from '../../../common/CopyClick';
import { HOSIcon } from '../../../common/icons';
import ODInfoBlurb from '../../../common/ODInfoBlurb';
import { AMERICAN_DATE_TIME, SHORT_DATE_TIME_TZ, getBrowserizedTime } from '../../../utils/datetimes';
import { DetailCardAccordionProps, IconWrapperProps } from '../types';
import {
  DISABLE_HOME_TIME,
  DISABLE_TMS_NOT_READY,
  DO_NOT_LINK,
  DRIVER,
  SELECT_ICON_CHECKBOX,
  SELECT_ICON_RADIO
} from '../helpers/constants';
import { DriverLoadDetailContext } from '../../../utils/context/driverLoadDetailContext';
import AssignmentWarning from '../shared/AssignmentWarning';
import { SWITCH_TO_ASSET } from '../../loadAcceptance/loadAcceptanceConstants';

const RequesterEmail = ({ email, timestamp, wip }: { email?: string, timestamp?: string, wip: boolean }) => {
  if (!email) return <></>;

  let datetime = '';
  if (!!timestamp) datetime = getBrowserizedTime(timestamp, AMERICAN_DATE_TIME, SHORT_DATE_TIME_TZ);

  return (
    <>
      {wip && (
        <Typography sx={{ textWrap: 'wrap' }}>
          This Driver is currently having a load sourced for a recommended dispatch match. Please review and discuss the driver internally before assigning it to its pending assignment.
        </Typography>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '18px auto',
          gap: 2,
          border: '1px solid',
          borderRadius: '4px',
          minHeight: 32,
          padding: '4px 16px',
          borderColor: 'inherit',
          ...(wip ? { my: 2 } : { mb: 2 })
        }}
      >
        <ConnectWithoutContactSharp sx={{ alignSelf: 'start', mt: '7px' }} />
        <Typography sx={{ alignSelf: 'center', whiteSpace: 'normal' }}>
          <strong>Requested By:&nbsp;</strong>
          {`${email}${!!datetime ? ` @ ${datetime}` : ''}`}
        </Typography>
      </Box>
    </>
  );
};

export const IconWrapper = ({ className, color, fullColor, drawBorder, extraStyles, children }: IconWrapperProps) => {
  const { showSourceSuggestions } = useFlags();

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '32px',
        width: '32px',
        borderRadius: '16px',
        boxSizing: 'border-box',
        ...((showSourceSuggestions && drawBorder) && { border: '1px solid', borderColor: 'level3' }),
        ...(fullColor ? { backgroundColor: color, color: 'text.inverse' } : { color }),
        '> p': {
          height: '18px',
          width: '18px',
          borderRadius: '9px',
          textAlign: 'center',
          lineHeight: '17px',
          ...(showSourceSuggestions ? { color: 'text.primary' }
            : { backgroundColor: color, fontSize: '12px', color: 'text.inverse', })
        },
        ...extraStyles
      }}
    >
      {children}
    </Box>
  );
};

export const DetailCardAccordion = ({
  type,
  driver,
  identifier,
  sequenceNumber,
  assignedId,
  selected,
  onOpenPermit,
  onShowOfferedLoad,
  hasPrerequisite = false,
  defaultCollapse = false,
  sourceSearch = null,
  warningReason,
  selectIcon,
  onClickSelect,
  onClickHos,
  onClickRules,
  children
}: DetailCardAccordionProps) => {
  const { linkDriverIds, showSourceSuggestions } = useFlags();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(selected && !defaultCollapse);

  useEffect(() => {
    setExpanded(selected && !defaultCollapse);
  }, [selected, defaultCollapse]);

  const { setDriverLoadDetailStatusObj } = useContext(DriverLoadDetailContext);

  const { label = '', copyText, description, icon } = identifier;

  return (
    <Box sx={{ borderTop: '1px solid', borderColor: 'inherit' }}>
      <Box sx={{ display: 'flex', columnGap: '16px', padding: '0px 16px', height: showSourceSuggestions ? '56px' : '48px', alignItems: 'center' }}>
        {(selectIcon && !!onClickSelect) && (
          <IconWrapper className="detail-card-accordion-select" extraStyles={{ mr: -1 }}>
            {selectIcon === SELECT_ICON_RADIO && (
              <ODRadio
                className="detail-card-accordion-radio"
                checked={selected}
                onClick={onClickSelect}
              />
            )}
            {selectIcon === SELECT_ICON_CHECKBOX && (
              <ODCheckbox
                className="detail-card-accordion-checkbox"
                checked={selected}
                onClick={onClickSelect}
              />
            )}
          </IconWrapper>
        )}
        {!!sequenceNumber && (
          <IconWrapper
            drawBorder={showSourceSuggestions}
            color={selected ? 'optimalblue.main' : 'colors.neutral4'}
            extraStyles={showSourceSuggestions && { marginRight: '-8px' }}
          >
            <Typography>{sequenceNumber}</Typography>
          </IconWrapper>
        )}
        {(!sequenceNumber || showSourceSuggestions) && <IconWrapper>{icon}</IconWrapper>}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
          {((!linkDriverIds && type === DRIVER) || type === DO_NOT_LINK || children === null) ? (
            <Typography><strong>{label}</strong></Typography>
          ) : (
            <CopyClick copyText={copyText ?? label}>
              <Typography
                sx={{ color: 'text.primary', textDecoration: 'underline', cursor: 'pointer' }}
                // @ts-expect-error This should get sorted when I properly separate DRIVER & LOAD
                onClick={() => type === DRIVER ? setDriverLoadDetailStatusObj(driver) : onShowOfferedLoad()}
              >
                <strong>{label}</strong>
              </Typography>
            </CopyClick>
          )}
          {description && <Typography>{description}</Typography>}
        </Box>
        {!!assignedId && (
          <Box
            sx={{
              display: 'flex',
              height: '32px',
              justifyContent: 'flex-end',
              alignItems: 'center',
              borderRight: '1px solid',
              borderColor: 'colors.neutral2',
              marginLeft: 'auto',
              '+ div': { marginLeft: 'unset' }
            }}
          >
            <CheckCircleOutlineSharp sx={{ color: 'success.main' }} />
            <Typography sx={{ pl: 1, pr: 2 }}><em>{`Assigned to ${assignedId}`}</em></Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', columnGap: '8px', cursor: 'pointer', marginLeft: 'auto' }}>
          {((!!warningReason && warningReason !== DISABLE_HOME_TIME) || (hasPrerequisite && !assignedId && type === DRIVER)) && (
            <IconWrapper
              color="warning.main"
              fullColor={true}
              onClick={() => setExpanded(!expanded)}
              extraStyles={{ color: 'text.primary' }}
            >
              {warningReason === DISABLE_TMS_NOT_READY ? <PriorityHighSharp /> : warningReason === SWITCH_TO_ASSET ? <Tooltip title="Assigned as Brokerage in your TMS"><ShuffleSharp /></Tooltip> : <InfoOutlined />}
            </IconWrapper>
          )}
          {!!onClickHos && (
            <ODTooltip title="HOS Clocks" placement="top">
              <ODAvatar variant="transparent" size="small" onClick={onClickHos}>
                <HOSIcon />
              </ODAvatar>
            </ODTooltip>
          )}
          {!!onClickRules && (
            <ODTooltip title="Driver Rules" placement="top">
              <ODAvatar variant="transparent" size="small" onClick={onClickRules}>
                <RuleSharp />
              </ODAvatar>
            </ODTooltip>
          )}
          {description && !!onOpenPermit && (
            <ODTooltip title="Permits & Requirements" placement="top">
              <ODAvatar variant="transparent" size="small" onClick={onOpenPermit}>
                <BadgeSharp />
              </ODAvatar>
            </ODTooltip>
          )}
          {children !== null && (
            <ODAvatar variant="transparent" size="small" onClick={() => setExpanded(!expanded)}>
              <ExpandMoreSharp sx={expanded ? { transform: 'rotate(0.5turn)' } : {}} />
            </ODAvatar>
          )}
        </Box>
      </Box>
      {hasPrerequisite && !assignedId && (
        <Box sx={{ mx: 2, mb: 1 }}>
          <ODInfoBlurb variant="warning" icon={<InfoOutlined sx={{ mt: '2px' }} />}>
            {type === DRIVER
              ? (
                <>
                  This load is a part of a Tour Match for this driver, and requires additional load(s) to be assigned to position the driver for this load.
                  &nbsp;
                  <Link component="button" variant="default" onClick={() => navigate(`/dispatching/by-driver/${driver?.driver_id}`)}>{`View ${driver?.driver_id}'s Matches`}</Link>
                </>
              ) : <>Load cannot be assigned to this driver because it&rsquo;s a relay, and the previous relays for this load ID have not yet been assigned.</>}
          </ODInfoBlurb>
        </Box>
      )}
      {(expanded && !!children) && (
        <Box sx={{ borderTop: '1px solid', borderColor: 'inherit', padding: '16px' }}>
          {warningReason && (
            <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid', borderColor: 'inherit' }}>
              <AssignmentWarning reason={warningReason} />
            </Box>
          )}
          {showSourceSuggestions && (
            <RequesterEmail
              email={driver?.source_requester_email ?? sourceSearch?.source_requester_email}
              timestamp={driver?.source_request_created_at ?? sourceSearch?.source_request_created_at}
              wip={!!driver?.has_source_request}
            />
          )}
          {children}
        </Box>
      )}
    </Box>
  );
};
