import React, { ReactElement, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { startCase } from 'lodash';
import { Box, Dialog, DialogProps, Typography } from '@mui/material';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import ExpandLessSharp from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import HelpOutlineSharp from '@mui/icons-material/HelpOutlineSharp';
import StarBorderSharp from '@mui/icons-material/StarBorderSharp';
import VisibilitySharp from '@mui/icons-material/VisibilitySharp';
import InsightsSharp from '@mui/icons-material/InsightsSharp';
import HomeSharp from '@mui/icons-material/HomeSharp';
import StarsSharp from '@mui/icons-material/StarsSharp';
import { ODAvatar, ODButton, ODCloseButton, ODNoLoadIcon } from '@OptimalDynamics/core-ai-common-ui';
import { TuneOffIcon } from '../../../common/icons';
import {
  DRIVER_AVAILABILITY,
  LOAD_AVAILABILITY,
  NON_OPTIMAL,
  ACCEPTABLE_MATCHES,
  RELAXED_MATCHES,
  NO_MATCHES,
  HOME_TIMES,
  FORECAST,
  ASSIGNED,
  OPTIMAL,
} from '../helpers/constants';

interface ChartInfoDatumProps {
  icon: ReactElement;
  datum: string;
  description?: string;
  expanded?: boolean;
  toggleExpand?: () => void;
}

const ChartInfoDatum = ({ icon, datum, description, expanded, toggleExpand }: ChartInfoDatumProps) => (
  <>
    <Box
      sx={{
        borderTop: '1px solid',
        borderColor: 'level3',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pl: 2,
        pr: '4px'
      }}
    >
      <Box sx={{ display: 'flex', columnGap: '16px', alignItems: 'center' }}>
        {icon}
        <Typography>{datum}</Typography>
      </Box>
      {(description && !!toggleExpand) && (
        <ODAvatar size="small" variant="transparent" onClick={() => toggleExpand()}>
          {expanded ? <ExpandLessSharp /> : <ExpandMoreSharp />}
        </ODAvatar>
      )}
    </Box>
    {(expanded && description) && <Typography sx={{ mt: 1 }}>{description}</Typography>}
  </>
);

interface ChartInfoDialogProps extends DialogProps {
  title: string;
  query: string;
  onClose: () => void;
  onNext?: () => void;
  onPrev?: () => void;
  imgSrc: string;
  open: boolean;
}

const ChartInfoDialog = (props: ChartInfoDialogProps): ReactElement => {
  const { title, query, onClose, onNext, onPrev, imgSrc, children, sx, open, ...rest } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '912px',
          height: '524px',
          boxShadow: 'none'
        },
        '&.MuiDialog-root': {
          zIndex: 1300
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, .25)'
        },
        ...sx
      }}
      {...rest}
    >
      <Box sx={{ display: 'flex', width: '912px', height: '100%' }}>
        <Box sx={{ width: '456px', backgroundSize: 'contain', backgroundImage: `url('${imgSrc}')`, backgroundColor: 'level2' }} />
        <Box
          sx={{
            height: 'calc(100% - 32px)',
            width: '392px',
            padding: '0 32px 32px 32px',
            display: 'grid',
            gridTemplateRows: '112px 40px 1fr 40px',
            rowGap: '16px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '18px' }}>{title}</Typography>
            <ODCloseButton onClick={onClose} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '16px',
              backgroundColor: 'level2',
              borderRadius: '4px',
              px: 2
            }}
          >
            <HelpOutlineSharp />
            <Typography>{query}</Typography>
          </Box>
          <Box sx={{ '> p': { px: 2, mb: 2 } }}>
            {children}
          </Box>
          <Box sx={{ display: 'flex', columnGap: '16px' }}>
            <ODButton disabled={!onPrev} sx={{ width: 'calc(50% - 8px)' }} onClick={onPrev}>Previous</ODButton>
            <ODButton variant="blue" disabled={!onNext} sx={{ width: 'calc(50% - 8px)' }} onClick={onNext}>Next</ODButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

interface ChartInfoSwitchboardProps {
  dispatchMethod: 'by-load' | 'by-driver';
  chartList: string[];
  infoIndex: number;
  setInfoIndex: (_idx: number) => void;
  viewType: string;
}

const ChartInfoSwitchboard = ({ dispatchMethod, chartList, infoIndex, setInfoIndex, viewType }: ChartInfoSwitchboardProps) => {
  const { dispatchingMetricVariations, showRelaxedMatches } = useFlags();
  const [expandedIndex, setExpandedIndex] = useState(0);

  useEffect(() => {
    setExpandedIndex(0);
  }, [infoIndex]);

  const mutuallyExclusiveExpand = (index: number) => {
    if (expandedIndex === index) setExpandedIndex(-1);
    else setExpandedIndex(index);
  };

  if (infoIndex < 0) return <></>;

  const chartType = chartList[infoIndex];

  let title = '';
  let query = '';
  let imgSrc = '/assets/images/macroexplainability/';
  let content = <Typography>Chart content goes here</Typography>;

  switch (chartType) {
    // Availability Charts
    case DRIVER_AVAILABILITY:
      title = 'Current Driver Availability Chart Guide';
      query = 'What am I seeing in this driver availability chart?';
      imgSrc = `${imgSrc}AvailabilityDriverPlanned.png`;
      content = (
        <>
          <Typography>Current Driver Availability is based on when your drivers are next available for dispatch (PTA). This chart displays the count of driver PTAs by timeframe. It also shows if there is a load assignment match available.</Typography>
          <ChartInfoDatum datum="Planned Drivers" icon={<CheckCircleSharp sx={{ color: 'success.main' }} />} />
          <ChartInfoDatum datum="Drivers That Have Optimal Matches" icon={<StarsSharp sx={{ color: 'optimalblue.main' }} />} />
          <ChartInfoDatum datum="Drivers Whose Assignment Needs More Review" icon={<VisibilitySharp sx={{ color: 'colors.neutral4' }} />} />
        </>
      );
      break;
    case LOAD_AVAILABILITY:
      title = 'Current Load Availability Chart Guide';
      query = 'What am I seeing in this load availability chart?';
      imgSrc = `${imgSrc}AvailabilityLoadAssigned.png`;
      content = (
        <>
          <Typography>Current Load Availability is based on when your loads need to be picked up. This chart displays count of load pick ups across time. It also shows if the loads are assigned to a driver, have a driver assignment match, or no match.</Typography>
          <ChartInfoDatum datum="Assigned Loads" icon={<CheckCircleSharp sx={{ color: 'success.main' }} />} />
          <ChartInfoDatum datum="Loads That Have Optimal Matches" icon={<StarsSharp sx={{ color: 'optimalblue.main' }} />} />
          <ChartInfoDatum datum="Load Assignments That Need More Review" icon={<VisibilitySharp sx={{ color: 'colors.neutral4' }} />} />
        </>
      );
      break;

    // Needs Review Charts
    case ACCEPTABLE_MATCHES:
      title = 'Good or Feasible Matches Guide';
      query = 'What does a good or feasible match mean?';
      imgSrc = `${imgSrc}NeedsReviewAcceptable.png`;
      content = (
        <ChartInfoDatum
          datum="Good or Feasible Matches"
          icon={<StarBorderSharp />}
          description="There are no optimal matches available for this driver or load but you can assign good or feasible matches."
          expanded={true}
        />
      );
      break;
    case RELAXED_MATCHES:
      title = 'Exception Matches Guide';
      query = 'What does an exception match mean?';
      imgSrc = `${imgSrc}NeedsReviewExceptions.png`;
      content = (
        <ChartInfoDatum
          datum="Exception Matches"
          icon={<TuneOffIcon />}
          description="There are available matches that fit within your exception logic. Please review and proceed as needed."
          expanded={true}
        />
      );
      break;
    case NO_MATCHES:
      title = 'No Available Matches Guide';
      query = 'What does no available matches mean?';
      imgSrc = `${imgSrc}NeedsReviewNoAvail.png`;
      content = (
        <ChartInfoDatum
          datum="No Available Matches"
          icon={<ODNoLoadIcon />}
          description={`There are no available matches that follow your business ${showRelaxedMatches ? 'or exception ' : ''}rule logic. Check data issues or source loads to create new opportunities.`}
          expanded={true}
        />
      );
      break;
    case HOME_TIMES:
      title = 'Home Time Guide';
      query = 'What does home time mean?';
      imgSrc = `${imgSrc}NeedsReviewHomeTime.png`;
      content = (
        <ChartInfoDatum
          datum="Home Time"
          icon={<HomeSharp />}
          description="Send the driver home, wait for more loads to be matched, or manually book a load for this driver to minimize deadhead along the route to home."
          expanded={true}
        />
      );
      break;

    default: // METRICS
      title = 'Metrics';
      query = 'How should I read these metrics?';

      const relevantMetrics = dispatchingMetricVariations[dispatchMethod];
      if (!relevantMetrics || relevantMetrics.length === 0) return <></>; // Sanity Check
      const relevantSuffix = relevantMetrics.map(startCase).join('');
      imgSrc = `${imgSrc}Metrics${relevantSuffix}.png`;
      const showAssigned = relevantMetrics[0] === ASSIGNED; // Always in the 1st position
      const showOptimal = relevantMetrics.includes(OPTIMAL); // Can be 1st or 2nd position
      const showForecast = relevantMetrics[relevantMetrics.length - 1] === FORECAST; // Always last
      
      content = (
        <>
          {showAssigned && (
            <ChartInfoDatum
              icon={<CheckCircleSharp sx={{ color: 'success.main' }} />}
              datum="Assigned"
              expanded={expandedIndex === 0 || !(showOptimal || showForecast)}
              toggleExpand={() => mutuallyExclusiveExpand(0)}
              description="Displays the simulated KPIs that you will achieve if your drivers perform all their currently assigned freight from your TMS."
            />
          )}
          {showOptimal && (
            <ChartInfoDatum
              icon={<StarsSharp sx={{ color: 'optimalblue.main' }} />}
              datum={showAssigned ? 'Assigned + Optimal' : 'Optimal Matches'}
              expanded={expandedIndex === 1 || !(showAssigned && showForecast)}
              toggleExpand={() => mutuallyExclusiveExpand(1)}
              description={showAssigned
                ? 'Displays the simulated KPIs that you will achieve if your drivers perform all their currently assigned freight, and then take the optimal matches that are ready to assign.'
                : 'These metrics indicate what can be achieved when your drivers complete all their ready-to-assign optimal matches.'}
            />
          )}
          {showForecast && (
            <ChartInfoDatum
              icon={<InsightsSharp sx={{ color: 'optimalblue.main' }} />}
              datum="Rolling 7 Day Forecast"
              description={`A simulation is run using${showAssigned ? ' your current assignments and' : ''} the optimal matches available and then forecasts 7 days into the future. Based on the results of this simulation, these are the KPIs you should expect.`}
              expanded={expandedIndex === 2 || !(showAssigned || showOptimal)}
              toggleExpand={() => mutuallyExclusiveExpand(2)}
            />
          )}
        </>
      );
  }

  // Because all 3 of the Positive Metrics have the same info, this short circuits & treats indices 2-n as 2
  const metricShortCircuit = viewType !== NON_OPTIMAL ? (infoIndex > 1 && infoIndex < chartList.length) : false;
  const isLast = infoIndex === (chartList.length - 1) || metricShortCircuit;

  return (
    <ChartInfoDialog
      open={true}
      onClose={() => setInfoIndex(-1)}
      onNext={isLast ? undefined : (() => setInfoIndex(infoIndex + 1))}
      onPrev={infoIndex === 0 ? undefined
        : metricShortCircuit ? (() => setInfoIndex(1))
          : (() => setInfoIndex(infoIndex - 1))}
      title={title}
      query={query}
      imgSrc={imgSrc}
    >
      {content}
    </ChartInfoDialog>
  );
};

export default ChartInfoSwitchboard;
