import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import { ODLaneIcon } from '@OptimalDynamics/core-ai-common-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LOADED, DRIVER, LOAD } from './constants';

export const driverNameHelper = (addDriverNameUi, driver) => {
  if (!driver) return '';

  const { driver_last_name: lastName, driver_first_name: firstName, driver_id: driverId, is_sleeper: isTeam } = driver;

  let driverLabel = driverId;
  if (addDriverNameUi && !!lastName && !!firstName) driverLabel = `${lastName}, ${firstName}`;

  return `${isTeam ? 'TEAM: ' : ''}${driverLabel}`;
};

export const DriverIdentifier = ({ driver }) => {
  const { addDriverNameUi } = useFlags();

  if (!driver) return <></>;
  return <>{driverNameHelper(addDriverNameUi, driver)}</>;
};

export const dynamicDescription = (selectedObj, targetObj, selectedObjType) => {
  if (!selectedObj || !targetObj) return null;

  if (!selectedObjType) {
    return dynamicDescription([targetObj], { driver: selectedObj }, LOAD);
  }

  const filteredSelectedObj = selectedObj.length > 1
    ? selectedObj.filter((opt) => opt.movement_type === LOADED && !!opt.active)
    : selectedObj;

  return (
    <Box
      sx={{
        minHeight: '20px',
        backgroundColor: 'colors.lightBlue1',
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: '4px',
        padding: '10px 16px',
        fontSize: '14px'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px', mr: 1 }}>
        {selectedObjType === LOAD ? (targetObj?.driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />) : <ODLaneIcon />}
        <Typography maxWidth={112} noWrap>{selectedObjType === LOAD ? <DriverIdentifier driver={targetObj?.driver} /> : targetObj?.load_id}</Typography>
        <ArrowForward sx={{ mx: 1 }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ...filteredSelectedObj.length > 1 ? { flexDirection: 'column', rowGap: '10px' } : { flexDirection: 'row', columnGap: '8px' }
        }}
      >
        {filteredSelectedObj.length > 1 ? (
          filteredSelectedObj?.map((obj, index) => (
            <Box key={`confirm-${index}`} sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
              <Avatar sx={{ width: 18, height: 18, fontSize: 12, fontWeight: 600, backgroundColor: 'optimalblue.main', color: 'colors.white' }}>
                {index + 1}
              </Avatar>
              {selectedObjType === LOAD ? obj?.load_id : <DriverIdentifier driver={obj} />}
            </Box>
          ))
        ) : (
          <>
            {selectedObjType === DRIVER ? (filteredSelectedObj?.[0]?.is_sleeper ? <PeopleSharp /> : <PersonSharp />) : <ODLaneIcon />}
            <Typography maxWidth={112} noWrap>{selectedObjType === DRIVER ? <DriverIdentifier driver={filteredSelectedObj?.[0]} /> : filteredSelectedObj?.[0]?.load_id}</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
